import { Component, Input } from '@angular/core';
import {
    AbstractControl,
    FormControl,
    ReactiveFormsModule,
} from '@angular/forms';
import { ParamsTextarea } from './interface';
import { IconComponent } from '@shared/ui-elements/icon/icon.component';

@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
    standalone: true,
    imports: [ReactiveFormsModule, IconComponent],
})
export class TextareaComponent {
    private _control!: FormControl;
    get control(): FormControl {
        return this._control;
    }
    @Input() set control(value: AbstractControl) {
        this._control = <FormControl>value;
    }

    private _params: ParamsTextarea = {};
    get params(): ParamsTextarea {
        return this._params;
    }
    @Input() set params(value: ParamsTextarea) {
        Object.assign(this._params, value);
    }

    clear() {
        this.control.patchValue(null);
    }
}
