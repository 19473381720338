<div class="textarea-wrap">
    <textarea
        [formControl]="control"
        autocomplete="off"
        [class.clearable]="params.clearable"
        [class.high]="params.high"
        [attr.id]="params.id ? params.id : null"
        [attr.name]="params.name ? params.name : null"
        [attr.placeholder]="params.placeholder ? params.placeholder : null"
        [attr.readonly]="params.readonly ? '' : null"
        [attr.disabled]="params.disabled ? '' : null"
    ></textarea>
    @if (params.clearable) {
        <span (click)="clear()" title="Очистить поле" class="textarea-clear">
            <app-icon name="close" />
        </span>
    }
</div>
